<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box px-2">
          <router-link to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm.png" alt height="48" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-dark.png" alt height="48" />
            </span>
          </router-link>

          <router-link to="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/logo-sm-light.png" alt height="42" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo-light.png" alt height="42" />
            </span>
          </router-link>
        </div>

        <button
          @click="toggleMenu"
          type="button"
          class="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn me-0 text-primary"
          id="vertical-menu-btn"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>

        <!-- App Search-->
        
      </div>
      <div class="mx-3 w-100 d-none d-md-block">
          <div class="input-group rounded-pill">
            <input type="search" placeholder="Search..." v-model="keyword" class="form-control px-4  rounded-pill rounded-end" @change="search">
            <select class="form-select bg-light rounded-pill rounded-start px-3" aria-label="" style="max-width: 120px;" v-model="searchType">
                <option value="market">Market</option>
                <option value="poison">Poison</option>
              </select>
          </div>
        </div>
      <div class="d-flex">
        <b-dropdown
          variant="white"
          class="dropdown d-inline-block d-none"
          toggle-class="header-item noti-icon"
          right
          menu-class="dropdown-menu-lg p-0 dropdown-menu-end"
        >
        
          <template v-slot:button-content>
            <i class="uil-bell"></i>
            <span class="badge bg-danger rounded-pill" v-if="parseInt(unread_notification)!==0">{{unread_notification}}</span>
          </template>
          

          <div class="p-3 border-bottom">
            <div class="row align-items-center">
              <div class="col">
                <h5 class="m-0 font-size-16">
                  Notifications
                </h5>
              </div>
              <div class="col-auto d-none">
                <a href="#!" class="small text-muted">Mark all as unread</a>
              </div>
            </div>
          </div>
          <simplebar style="max-height: 300px" data-simplebar >
            
            <div v-if="loading">
            <a href class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs me-3">
                  <span
                    class="avatar-title bg-soft-primary text-primary rounded-circle font-size-16"
                  >
                    <i class="uil uil-file-graph"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1 font-size-14 placeholder-glow">
                    <span class="placeholder rounded col-12"></span>
                    <span class="placeholder rounded col-6"></span>
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1 placeholder-glow">
                     <span class="placeholder rounded col-7"></span>
                    </p>
                    <p class="mb-0 placeholder-glow">
                      <span class="placeholder rounded col-5"></span>
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a href class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs me-3">
                  <span
                    class="avatar-title bg-soft-primary text-primary rounded-circle font-size-16"
                  >
                    <i class="uil uil-file-graph"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1 font-size-14 placeholder-glow">
                    <span class="placeholder rounded col-12"></span>
                    <span class="placeholder rounded col-6"></span>
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1 placeholder-glow">
                     <span class="placeholder rounded col-7"></span>
                    </p>
                    <p class="mb-0 placeholder-glow">
                      <span class="placeholder rounded col-5"></span>
                    </p>
                  </div>
                </div>
              </div>
            </a>
            </div>
            <div v-else>
              <a href="javascript:void(0)" class="text-reset notification-item" v-for="(value, index) in notifications" :key="index">
                <div class="media">
                  <div class="avatar-xs me-3">
                    <span
                      class="avatar-title bg-soft-primary text-primary rounded-circle font-size-16"
                    >
                      <i class="uil uil-file-graph"></i>
                    </span>
                  </div>
                  <div class="media-body">
                    <h6 class="mt-0 mb-1 font-size-14 placeholder-glow">
                      {{value.title}}
                    </h6>
                    <div class="font-size-12 text-muted">
                      <p class="mb-1 placeholder-glow">
                        Processed by: {{value.processed_by}}
                      </p>
                      <p class="mb-0 placeholder-glow">
                        <i class="mdi mdi-clock-outline"></i>
                        {{value.date_time}}
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </simplebar>
          <div class="p-2 border-top d-none">
            <div class="d-grid">
              <a
                class="btn btn-sm btn-link font-size-14 text-center fw-medium"
                href="javascript:void(0)"
              >
                <i class="uil-arrow-circle-right me-1"></i>
                Load More..
              </a>
            </div>
          </div>
        </b-dropdown>
       
        <div class="d-flex align-items-center">
          <button type="button" class="btn btn-light pb-1 rounded-pill align-items-center position-relative lh-0 me-2" @click="$router.push({name:'cart'}).catch(() => { })" >
              <i class="bi bi-cart4 font-size-20 position-relative lh-1" style="top: -3px"></i>
              <span class="badge bg-primary rounded-pill font-size-9 position-absolute" style="top: -3px; right:-2px">
                {{ totalCart|| 0}}
              </span>
          </button>
          <button type="button" class="btn btn-light pb-1 rounded-pill align-items-center position-relative lh-0" @click="$router.push({name:'my favourites'}).catch(() => { })" >
              <i class="bi bi-heart font-size-20 lh-1"></i>
              <span class="badge bg-primary rounded-pill font-size-9 position-absolute" style="top: -3px;right:-2px">
                {{totalFavourite || 0}}
              </span>
          </button>
        </div>
        <b-dropdown
          class="d-inline-block"
          toggle-class="header-item"
          right
          variant="white"
          menu-class="dropdown-menu-end"
        >
          <template v-slot:button-content>
            <div class="d-flex align-items-center">
            <span
              class="ms-1 fw-medium font-size-15 me-2 text-dark"
              > {{userData.name}}</span
            >
            <span class="avatar-xs">
                <span class="avatar-title rounded-circle bg-primary text-white" v-if="userData.name">
                  {{ userData.name.charAt(0).toUpperCase() }}
                </span>
            </span>
            
            <!-- <i class="uil-angle-down d-none d-xl-inline-block font-size-15"></i> -->
          </div>
          </template>

         
          <!-- <router-link to="/" class="dropdown-item d-block">
            <i
              class="uil uil-cog font-size-18 align-middle me-1 text-muted"
            ></i>
            <span class="align-middle">Activity Log</span>
          </router-link> -->
          <router-link to="/settings" class="dropdown-item">
            <i
              class="bi bi-gear font-size-18 align-middle me-1 text-muted"
            ></i>
            <span class="align-middle"> Change Password</span>
          </router-link>
          <router-link to="/logout" class="dropdown-item">
            <i
              class="bi bi-box-arrow-left font-size-18 align-middle me-1 text-muted"
            ></i>
            <span class="align-middle"> {{$t("siteLang.Logout") }}</span>
          </router-link>
        </b-dropdown>

      </div>
    </div>
    <Common ref="commonFunc"/>
  </header>
</template>
<script>
import appConfig from "@/app.config";
import Common from "@/components/common";
import Swal from "sweetalert2";
import simplebar from "simplebar-vue";
import axios from "axios";
const CancelToken = axios.CancelToken;
let cancel;

export default {
  components: {
    Common,
    simplebar
  },
  // props: {
  //   totalCart: {
  //     type: Number,
  //     required: true,
  //   },
  //   totalFavourite: {
  //     type: Number,
  //     required: true,
  //   }
  // },
  data() {
    return {
      userData:{},
      notifications:[],
      unread_notification:"-",
      account_type:'',
      accessToken:'',
      accessEmail:'',
      accessPhone:'',
      accessUsername:'',
      loading:false,
      title: '',
      totalCart:0,
      totalFavourite:0,
      keyword:"",
      searchType:"market"
    };
  },
  // watch: {
  //   userData: {
  //     handler(newVal) {
  //       console.log('dataProp has changed:', newVal);
  //     },
  //     deep: true, // Watch for deep changes within the object
  //   },
  // },
  async mounted() {
    this.reload();
    this.accessToken = this.$refs.commonFunc.getToken()
    this.accessUsername = this.$refs.commonFunc.getUsername()
    //this.userData = this.$refs.commonFunc.getFullData()
    //this.getData();
    this.$root.$on('bv::dropdown::show', this.handleDropdownShow);

   
  },
  beforeDestroy() {
    // Remove the event listener when the component is about to be destroyed
    this.$root.$off('bv::dropdown::show', this.handleDropdownShow);
  },
  methods: {
    /**
     * Toggle menu
     */
     handleDropdownShow(bvEvent) {
      // This function will be called when the dropdown is about to be shown
      console.log('Dropdown is about to be shown', bvEvent);
      if (bvEvent.type=='show'){
        this.readMessageAndUpdate(0)
      }
    },
    search(){
      if (this.searchType=="market"){
        this.$router.push({
          name: 'nova-market', 
          query: { sortBy:'', keyword: this.keyword } 
        }).catch(() => {});
        if (this.$route.name=='nova-market'){
          window.location.reload();
        }
        console.log(`Search1: ${this.searchType},  Keyword: ${this.keyword}`);
      }else{
        this.$router.push({
          name: 'poison-products', 
          query: { keyword: this.keyword } 
        }).catch(() => {});
        if (this.$route.name=='poison-products'){
          window.location.reload();
        }
        console.log(`Search2: ${this.searchType},  Keyword: ${this.keyword}`);
      }
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    /**
     * Toggle rightsidebar
     */
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    /**
     * Set languages
     */
    setLanguage(locale, country, flag) {
      this.$i18n.locale = locale;
      this.current_language = locale;
      this.text = country;
      this.flag = flag;
    },
    async getData(){
        if(cancel !== undefined) cancel();
        this.$Progress.start();
        this.loading = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername",  this.accessUsername);
        axios({
            method: "post",
            url: appConfig.DemoAPI + '?api=notification',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
            cancelToken: new CancelToken(c => cancel = c)
        })
        .then((response)=>{
            var resData = response.data;
            if (resData.status == 200) {
              console.log("run Notification")
              console.log(resData)
              var unread_total = 3
              var returnData = [
              {
                  title: `Rejected "CIA Protection Sdn Bhd" factoring application form.`,
                  processed_by: "Hui Kiat Bin",
                  date_time: "04/11/2023 11:58:00",
                },
                {
                  title: `Approved "CIA Protection Sdn Bhd" factoring application form.`,
                  processed_by: "Hui Kiat Bin",
                  date_time: "04/11/2023 11:58:00",
                },
                {
                  title: `Edited "CIA Protection Sdn Bhd" factoring application form.`,
                  processed_by: "Razir Mohar",
                  date_time: "04/11/2023 11:58:00",
                },
                {
                  title: `Added a new "CIA Protection Sdn Bhd" factoring application form.`,
                  processed_by: "Razir Mohar",
                  date_time: "02/11/2023 11:58:00",
                },
              ];
              this.readMessageAndUpdate(unread_total);
              this.notifications = returnData
             
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            console.log(error);
            // Swal.fire(
            //   {
            //   icon: 'error',
            //   title: 'Oops...',
            //   html: `${error}.`,
            //   confirmButtonColor: '#222',
            //   confirmButtonText: this.$t('siteLang.Done'),
            // })
      });
    },
    async readMessageAndUpdate(value) {
      try {
        console.log(value);
        var data = value;
        if (parseInt(data) > 99) {
          this.unread_notification = "99+";
        } else {
          this.unread_notification = data;
        }
        var updateData = {
          "unread_notifications": data,
        };

        // Assuming updateData returns a Promise
        await this.$refs.commonFunc.updateData(JSON.stringify(updateData));

        this.unread_notification = data;
      } catch (error) {
        console.error("Error:", error);
      }
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted

        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.account_type = data.account_type;
          this.unread_notification = data.unread_notifications;
          this.userData = data;

          this.totalCart =  data.cart || 0;
          this.totalFavourite = data.favourite || 0;
          console.log(data.account_type)
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },
  },
};
</script>
<style scoped>
.notification-item .media{
  border-bottom: 1px solid #eee !important;
}
</style>